import React from 'react';
import styled from 'styled-components';
import { forSize, colors } from 'src/style-utils';
import { H1, ActionButton } from 'src/components/base';

const NotFoundSection = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  padding: 120px;

  ${forSize.phoneOnly`
    padding: 20px;
  `};
`;

const HugeHeading = styled(H1)`
  color: ${colors.lightBrand};

  font-size: 100px;
  font-weight: 700;
  line-height: 120px;
`;

const browser = typeof window !== 'undefined' && window;

export default function NotFoundPage() {
  return (
    browser && (
      <NotFoundSection>
        <HugeHeading>404</HugeHeading>
        <ActionButton tag="a" href="/" text="Return to homepage" />
      </NotFoundSection>
    )
  );
}
